import React, { useEffect, useState } from 'react';
import { Button, View, Text, Collection, Card, TextField, Heading, Table, TableHead, TableBody, TableRow, TableCell, CheckboxField, Loader } from '@aws-amplify/ui-react';

import { Amplify, API } from 'aws-amplify';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaCircleXmark } from 'react-icons/fa6';

export default function Water () {
    
    let [device, setDevice] = useState(null);
    let [version, setVersion] = useState(0);
    let [sensors, setSensors] = useState([]);
    let { id } = useParams();

    useEffect(function () {
        let mounted = true;

        API.get("pspDevice", "/Device/get/" + id)
            .then(function (device) {
                if (!device.wateringCan.config) {
                    device.wateringCan.config = { food : [], plant : [], sensor : [], sensor : []};
                }

                if (!device.wateringCan.config.sensor) {
                    device.wateringCan.config.sensor = [];
                }

                while (device.wateringCan.config.sensor.length < (device.sensorCount || 16)) {
                    device.wateringCan.config.sensor.push({ min: 0, max : 0 });
                }

                if (mounted) {
                    setDevice(device);
                    setSensors(device.wateringCan.config.sensor);
                }
                
            })

        return () => { mounted = false; }
    }, [id]);

    function updateSensor() {
        API.post(
                "pspDevice",
                "/Device/update/" + id + "/watering-can/config/sensor",
                {
                    body: {
                        sensor : (device.wateringCan.config || {}).sensor || []
                    }
                }
            )
            .then(function (result) {
                console.log(result);
            })
            .catch(function (e) {
                console.warn(e, e.stack);
            })
    }
    
    function updateMin(switches, index, event) {
        if (event.target.value) {
            sensors[index].min = parseInt(event.target.value, 10);
        } else {
            sensors[index].min = 0
        }

        setSensors(sensors);
        setVersion(version + 1);
    }

    function updateMax(switches, index, event) {
        if (event.target.value) {
            sensors[index].max = parseInt(event.target.value, 10);
        } else {
            sensors[index].max = 0
        }

        setSensors(sensors);
        setVersion(version + 1);
    }

    if (device) {
        let wateringCan = device.wateringCan;
        let sensorCount = (device.sensorCount || 8);

        return (
            <View>
                <Heading level="1">{wateringCan.env.name}</Heading>
                <ol className = "breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to={"/Device/" + id}>{device.deviceId}</Link></li>
                    <li>Water</li>
                </ol>
                <Heading level="5">Sensors</Heading>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell as="th">NO.</TableCell>
                            <TableCell as="th">Min</TableCell>
                            <TableCell as="th">Max</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { sensors.map(function (sensor,idx) {
                            let labelIndex = Math.floor(idx / 2);
                            return <TableRow key={idx}>
                                <TableCell>
                                    {(labelIndex + 1) + " " + ((idx % 2) ? "B" : "A") + " (" + idx + ")"}
                                </TableCell>
                                <TableCell>
                                    <TextField type="number" min="0" max="4096" step="1" value={sensor.min} onChange={updateMin.bind(null, sensors, idx)} />
                                </TableCell>
                                <TableCell>
                                    <TextField type="number" min="0" max="4096" step="1" value={sensor.max} onChange={updateMax.bind(null, sensors, idx)} />
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody> 
                </Table>
                <Button onClick={updateSensor}>Save</Button>
            </View>
        );
    } else {
        return ( <View><Loader size="large" /></View> ) ;
    }
}
