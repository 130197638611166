import logo from './wifi-watering-can.svg';
import './App.css';

import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import { Auth , Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';

import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";

import Index from "./routes/Index";
import Device from "./routes/Device";

import Plant from "./routes/Device/config/plant";
import Water from "./routes/Device/config/water";
import Sensor from "./routes/Device/config/sensor";
import CropManager from "./routes/Device/config/cropManager";

import RelayPlans from  "./routes/Device/RelayPlans";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);


const router = createHashRouter([
    {
        path: "/",
        element: <Index /> 
    },
    {
        path: "/Device/:id/config/plant",
        element: <Plant />
    },
    {
        path: "/Device/:id/config/water",
        element: <Water />
    },
    {
        path: "/Device/:id/config/sensor",
        element: <Sensor />
    },
    {
        path: "/Device/:id/config/cropManager",
        element: <CropManager />
    },
    {
        path: "/Device/:id/relayPlans",
        element: <RelayPlans />
    },
    {
        path: "/Device/:id",
        element: <Device />
    }
]);

function App() {
  return <RouterProvider router={router} />
}

export default withAuthenticator(App);
