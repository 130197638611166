import React, { useEffect, useState } from 'react';
import { Button, View, Text, Collection, Card, TextField, Heading, Table, TableHead, TableBody, TableRow, TableCell, CheckboxField, Loader } from '@aws-amplify/ui-react';

import { Amplify, API } from 'aws-amplify';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaCircleXmark } from 'react-icons/fa6';
import deviceUtil from '../util';

export default function CropManager () {
    let [device, setDevice] = useState(null);
    let [version, setVersion] = useState(0);
    let { id } = useParams();

    useEffect(function () {
        let mounted = true;

        API.get("pspDevice", "/Device/get/" + id)
            .then(function (device) {
                if (mounted) {
                    setDevice(device);
                }
            })

        return () => { mounted = false; }
    }, [id]);

    function updateCropManager() {
        API.post(
                "pspDevice",
                "/Device/update/" + id + "/watering-can/config/cropManager",
                {
                    body: {
                        cropManager : ((device.wateringCan || {}).config || {}).cropManager
                    }
                }
            )
            .then(function (result) {
                console.log(result);
            })
            .catch(function (e) {
                console.warn(e, e.stack);
            })
    }



    if (device) {
        let relayPlanCount = (device.wateringCan.config || {}).planCount || 16;
        let switchCount = (device.wateringCan.config || {}).switchCount || 16;
        let sensorCount = (device.wateringCan.config || {}).sensorCount || 16;
        let relayCount = (device.relayCount || 8);

        let cropManager = (device.wateringCan.config || {}).cropManager

        if (!cropManager) {
            device.wateringCan.config.cropManager = cropManager = {
                days : Array(7).fill(false),
                hours : Array(24).fill(false),
                weeks : 1,
                period : 10,
                kd : 0,
                ki : 0,
                kp : 0
            };
        }

        function updateField(obj, field, e) {
            obj[field] = parseFloat(e.target.value);
            setVersion(version + 1);
        }

        function updateCheckboxArray(array, index, e) {
            if (index >= array.length) {
                return;
            }

            array[index] = e.target.checked;
            setVersion(version + 1);
        }


        return (
            <View>
                <Heading level="1">{device.wateringCan.env.name}</Heading> 
                <ol className = "breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to={"/Device/" + id}>{device.deviceId}</Link></li>
                    <li>Crop Manager</li>
                </ol>
                <Card borderRadius="medium" variation="outlined">
                    <Heading level="5">Days of Week</Heading>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell as="th">Sunday</TableCell>
                                <TableCell as="th">Monday</TableCell>
                                <TableCell as="th">Tuesday</TableCell>
                                <TableCell as="th">Wednesday</TableCell>
                                <TableCell as="th">Thursday</TableCell>
                                <TableCell as="th">Friday</TableCell>
                                <TableCell as="th">Saturday</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                { cropManager.days.map((dayChecked, index) => <TableCell key={index}>
                                    <CheckboxField key={index} checked={dayChecked} onChange={updateCheckboxArray.bind(null, cropManager.days, index)} />
                                </TableCell>)}
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Heading level="5">Hours</Heading>
                    <Table>
                        <TableHead>
                            <TableRow>
                                { cropManager.hours.slice(0,12).map((hourChecked, index) => <TableCell as="th" key={index}>{(index < 10 ? "0" + index : index) + ":00"}</TableCell>) }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                { cropManager.hours.slice(0,12).map((hourChecked, index) => <TableCell key={index} as="th">
                                    <CheckboxField key = {index} checked={hourChecked} onChange={updateCheckboxArray.bind(null, cropManager.hours, index)} />
                                </TableCell>) }
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Table>
                        <TableHead>
                            <TableRow>
                                { cropManager.hours.slice(12).map((hourChecked, index) => <TableCell as="th" key={index}>{(12 + index) + ":00"}</TableCell>) }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                { cropManager.hours.slice(12).map((hourChecked, index) => <TableCell as="th" key={index}>
                                    <CheckboxField key={index} checked={hourChecked} onChange={updateCheckboxArray.bind(null, cropManager.hours, 12 + index)} />
                                </TableCell>) }
                            </TableRow>
                        </TableBody>
                    </Table>
                    <TextField type="number" min="0" max="255" step="1" label="Period" style={{width: "9em" }} value={cropManager.period} onChange={updateField.bind(null, cropManager, 'period')}  />
                    <TextField type="number" min="0" max="655.35" step="0.01" label="Proportional Gain" style={{width: "9em" }} value={cropManager.kp} onChange={updateField.bind(null, cropManager, 'kp')}  />
                    <TextField type="number" min="0" max="655.35" step="0.01"  label="Derivative Gain" style={{width: "9em" }} value={cropManager.kd} onChange={updateField.bind(null, cropManager, 'kd')} />
                    <TextField type="number" min="0" max="655.35" step="0.01" label="Integral Gain" style={{width: "9em" }} value={cropManager.ki} onChange={updateField.bind(null, cropManager, 'ki')} />
                    <small>Proportional is the difference between the dryness target and current dryness.</small>
                </Card>
                <Button onClick={updateCropManager}>Save</Button>
            </View>
        );
    } else {
        return ( <View><Loader size="large" /></View> );
    }
}
