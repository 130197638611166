import React, { useEffect, useState } from 'react';
import { Button, View, Text, Collection, Card, TextField, Heading, Table, TableHead, TableBody, TableRow, TableCell, CheckboxField, Loader } from '@aws-amplify/ui-react';

import { Amplify, API } from 'aws-amplify';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaCircleXmark } from 'react-icons/fa6';

import deviceUtil from '../util';

export default function Plant () {

    let [device, setDevice] = useState(null);
    let [version, setVersion] = useState(0);
    let { id } = useParams();

    useEffect(function () {
        let mounted = true;

        API.get("pspDevice", "/Device/get/" + id)
            .then(function (device) {
                if (mounted) {
                    if (!device.wateringCan.config) {
                        device.wateringCan.config = {};
                    }

                    if (!device.wateringCan.config.plant) {
                        device.wateringCan.config.plant = [];
                    }

                    setDevice(device);
                }
            })

        return () => { mounted = false; }
    }, [id]);

    function updatePlants() {
        API.post(
                "pspDevice",
                "/Device/update/" + id + "/watering-can/config/plant",
                {
                    body: {
                        plant : (device.wateringCan.config || {}).plant || []
                    }
                }
            )
            .then(function (result) {
                console.log(result);

            })
            .catch(function (e) {
                console.warn(e, e.stack);
            })
    }

    function deletePlant(index) {
        let plant = (device.wateringCan.config || {}).plant[index];
        if (!plant) {
            return
        }

        if (window.confirm("Delete Plant: " + plant.name)) {
            device.wateringCan.config.plant.splice(index,1);
            setVersion(version + 1);
        }
    }

    if (device) {
        let wateringCan = device.wateringCan;
        let plants = (device.wateringCan.config || {}).plant || [];

        let sensorCount = (device.wateringCan.config || {}).sensorCount || 16;
        let switchCount = (device.wateringCan.config || {}).switchCount || 16;
        let plantCount = (device.wateringCan.config || {}).plantCount || 16;

        function addPlant() {
            if (plants.length < plantCount) {
                plants.push({
                    name : "",
                    drynessSensors : [].fill(false, 0, sensorCount),
                    drynessTarget : 0,
                    waterTarget : 0,
                    foodTarget : 0,
                    switches: [].fill(false, 0, switchCount)
                })
            }
            setVersion(version + 1);
        }

        function updatePlantNameIndex(index, e) {
            plants[index].name = e.target.value;
            setVersion(version + 1);
        }

        function updatePlantDrynessTargetIndex(index, e) {
            plants[index].drynessTarget = parseInt(e.target.value, 10) || 0;
            setVersion(version + 1);
        }

        function updatePlantDrynessSensorIndex(index, sensorIndex, e) {
            plants[index].drynessSensors[sensorIndex] = e.target.checked; 
            setVersion(version + 1);
        }

        function updatePlantSwitchesIndex(index, switchIndex, e) {
            plants[index].switches[switchIndex] = e.target.checked;
            setVersion(version + 1);
        }

        let sensorLabels = [];

        sensorLabels.fill("", 0, sensorCount);

        for (let i = 0;i < 8;i++) {
            let labelIndex = Math.floor(i / 2);
            sensorLabels[i] = (labelIndex + 1) + " " + ((i % 2) ? "B" : "A");
        }

        let switchLabels = new Array(switchCount);

        switchLabels.fill("_", 0, switchCount);

        for (let i = 0;i < (device.relayCount || 4);i++) {
            switchLabels[i] = "R" + (i + 1);
        }

        for (let i = 0;i < 4;i++) {
            switchLabels[8 + i] = "S" + (i + 1);
        }

        return (
            <View>
                <Heading level="1">{wateringCan.env.name}</Heading>
                <ol className = "breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to={"/Device/" + id}>{device.deviceId}</Link></li>
                    <li>Plants</li>
                </ol>
                <Collection type = "list" direction = "row" gap = "10px" wrap = "wrap" items={plants}>
                    {(plant, index) => (
                        <Card key={index} borderRadius="medium" variation="outlined">
                            <TextField maxLength={8} label="Name" style={{width: "9em" }} value={plant.name} onChange={updatePlantNameIndex.bind(null, index)} />
                            <TextField type="number" label="Dryness Target" min="0" max="4096" step="1" value={plant.drynessTarget} onChange={updatePlantDrynessTargetIndex.bind(null, index)} />
                            <Heading level="5">Dryness Sensors</Heading>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        { sensorLabels.map((label, index) => <TableCell key={index} as="th">{label}</TableCell>) }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        { sensorLabels.map((label, sensorIndex) => <TableCell key={sensorIndex}><CheckboxField checked={plant.drynessSensors[sensorIndex]} onChange={updatePlantDrynessSensorIndex.bind(null, index, sensorIndex)} /></TableCell>) }
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Heading level="5">Switches</Heading>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        { switchLabels.map((label, labelIndex) => <TableCell key={labelIndex} as="th">{label}</TableCell>) }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        { switchLabels.map((label, switchIndex) => <TableCell key={switchIndex}><CheckboxField checked={plant.switches[switchIndex]} onChange={updatePlantSwitchesIndex.bind(null, index, switchIndex)} /></TableCell>) }
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Button onClick={deletePlant.bind(null, index)}><FaCircleXmark /></Button>
                        </Card>
                    )}
                </Collection>
                { plants.length < plantCount ? <Button onClick={addPlant}>Add Plant</Button> : null }
                <Button onClick={updatePlants}>Save</Button>
            </View>
        );
    } else {
        return ( <View><Loader size="large" /></View> );
    }
}
