import React, { useEffect, useState } from 'react';
import { Button, View, Text, Collection, Card, TextField, Heading, Table, TableHead, TableBody, TableRow, TableCell, CheckboxField, Loader } from '@aws-amplify/ui-react';

import { Amplify, API } from 'aws-amplify';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaCircleXmark } from 'react-icons/fa6';

export default function Water () {
    
    let [device, setDevice] = useState(null);
    let [version, setVersion] = useState(0);
    let [relays, setRelays] = useState([]);
    let [servos, setServos] = useState([]);
    let { id } = useParams();

    useEffect(function () {
        let mounted = true;

        API.get("pspDevice", "/Device/get/" + id)
            .then(function (device) {
                if (!device.wateringCan.config) {
                    device.wateringCan.config = { food : [], plant : [], water : [] };
                }

                if (!device.wateringCan.config.water) {
                    device.wateringCan.config.water = [];
                }

                while (device.wateringCan.config.water.length < (device.wateringCan.config.switchCount || 16)) {
                    device.wateringCan.config.water.push({ microLiter: 0, prime : 0 });
                }

                let halfCount = (device.wateringCan.config.switchCount || 16) / 2;

                for (let i = 0;i < halfCount;i++) {
                    if (relays[i]) {
                        relays[i] = device.wateringCan.config.water[i];
                    } else {
                        relays.push(device.wateringCan.config.water[i]);
                    }

                }


                for (let i = 0;i < halfCount;i++) {
                    if (servos[i]) {
                        servos[i] = device.wateringCan.config.water[i + halfCount];
                    } else {
                        servos.push(device.wateringCan.config.water[i + halfCount]);
                    }
                }

                console.log(relays.length, servos.length);

                if (mounted) {
                    setDevice(device);
                    setRelays(relays);
                    setServos(servos);
                }
                
            })

        return () => { mounted = false; }
    }, [id]);

    function updateWater() {
        API.post(
                "pspDevice",
                "/Device/update/" + id + "/watering-can/config/water",
                {
                    body: {
                        water : (device.wateringCan.config || {}).water || []
                    }
                }
            )
            .then(function (result) {
                console.log(result);
            })
            .catch(function (e) {
                console.warn(e, e.stack);
            })
    }
    
    function updateMl(switches, index, event) {
        if (event.target.value) {
            switches[index].microLiter = parseFloat(event.target.value, 10) * 100;
        } else {
            switches[index].microLiter = 0
        }

        setVersion(version + 1);
    }

    function updatePrime(switches, index, event) {
        if (event.target.value) {
            switches[index].prime = parseInt(event.target.value, 10);
        } else {
            switches[index].prime = 0
        }

        setVersion(version + 1);
    }

    if (device) {
        let wateringCan = device.wateringCan;
        let halfCount = (device.wateringCan.config.switchCount || 16) / 2;

        return (
            <View>
                <Heading level="1">{wateringCan.env.name}</Heading>
                <ol className = "breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to={"/Device/" + id}>{device.deviceId}</Link></li>
                    <li>Water</li>
                </ol>
                <Heading level="5">Relays</Heading>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell as="th">NO.</TableCell>
                            <TableCell as="th">Ml</TableCell>
                            <TableCell as="th">Prime</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { relays.map(function (relay,idx) {
                            return <TableRow key={idx}>
                                <TableCell>
                                    R{idx + 1}
                                </TableCell>
                                <TableCell>
                                    <TextField type="number" min="0" max="65535" step="0.01" value={relay.microLiter / 100} onChange={updateMl.bind(null, relays, idx)} />
                                </TableCell>
                                <TableCell>
                                    <TextField type="number" min="0" max="65535" value={relay.prime} onChange={updatePrime.bind(null, relays, idx)} />
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody> 
                </Table>

                <Heading level="5">Servos</Heading>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell as="th">NO.</TableCell>
                            <TableCell as="th">Ml</TableCell>
                            <TableCell as="th">Prime</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { servos.map(function (servo,idx) {
                            return <TableRow key={idx}>
                                <TableCell>
                                    S{idx + 1}
                                </TableCell>
                                <TableCell>
                                    <TextField type="number" min="0" max="65535" step="0.01" value={servo.microLiter / 100} onChange={updateMl.bind(null, servos, idx)} />
                                </TableCell>
                                <TableCell>
                                    <TextField type="number" min="0" max="65535" value={servo.microLiter / 100} onChange={updatePrime.bind(null, servos, idx)} />
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody> 
                </Table>
                <Button onClick={updateWater}>Save</Button>
            </View>
        );
    } else {
        return ( <View><Loader size="large" /></View> ) ;
    }
}
