import React, { useEffect, useState } from 'react';
import { SelectField, StepperField, View, Heading, Collection, Card, Text, Divider, Button,Loader } from '@aws-amplify/ui-react';

import { Amplify, API } from 'aws-amplify';
import { useParams, Link } from "react-router-dom";

export default function Device () {
    let [device, setDevice] = useState(null);
    let [relayChannel, setRelayChannel] = useState(0);
    let [relaySeconds, setRelaySeconds] = useState(10);
    let [relayOpen, setRelayOpen] = useState(false);
    let { id } = useParams();

    useEffect(function () {
        let mounted = true;
        API.get("pspDevice", "/Device/get/" + id)
            .then(function (device) {
                if (!mounted) {
                    return;
                }
                
                setDevice(device);
            })

        return () => { mounted = false; }
    }, []);

    async function openRelay() {
        console.log("openRelay:", relayChannel, relaySeconds);
        setRelayOpen(true);
        try {

            await API.post(
                "pspIot",
                "/Iot/" + id + "/publish",
                {
                    body : {
                        topic: "watering-can/relay/command",
                        payload: relayChannel + "," + relaySeconds
                    }
                }
            );

            setTimeout(function () {
                setRelayOpen(false);
            }, 1000 * relaySeconds);
        } catch (e) {
            console.warn(e, e.stack);
            setRelayOpen(false);
        }
    }

    if (device) {
        let wateringCan = device.wateringCan || {}
        let relayCount = device.relayCount;
        let relays = [];

        for (let i = 0;i < relayCount;i++) {
            let label = "R" + (i + 1).toString();
            let names = idsFromSwitchIndex(wateringCan, i);

            if (names.length) {
                label = label + " " + names.join(", ")
            }

            relays.push({
                index : i,
                label : label 
            });
        }

        return (
            <View>
                <Heading level="1">{wateringCan.env.name}</Heading>
                <Text>{device.deviceId}</Text>
                <Divider padding="xs" />
                <Card borderRadius="medium" maxWidth="40rem" variation="outlined">
                    <Heading>Relays</Heading>
                    <SelectField label = "Channel" value={relayChannel} onChange={(e) => setRelayChannel(e.target.value)}>
                        { relays.map(relay => ( <option key={relay.index} value={relay.index}>{relay.label}</option> )) }
                    </SelectField>
                    <StepperField min={1} max={240} step={1} defaultValue={10} value={relaySeconds} onStepChange={(val) => setRelaySeconds(val)} label="Seconds" /> 
                    <Button isDisabled={relayOpen} onClick={openRelay}>Open</Button>
                </Card>
                <Card borderRadius="medium" maxWidth="40rem" variation="outlined">
                    <Heading>Config</Heading>
                    <Link to={"/Device/" + id + "/config/plant"}><Button>Plants</Button></Link>
                    <Link to={"/Device/" + id + "/config/water"}><Button>Water</Button></Link>
                    <Link to={"/Device/" + id + "/config/sensor"}><Button>Sensors</Button></Link>
                    <Link to={"/Device/" + id + "/config/cropManager"}><Button>Crop Manager</Button></Link>
                    <Link to={"/Device/" + id + "/relayPlans"}><Button>Relay Plans</Button></Link>
                </Card>
            </View>
        );
    } else {
        return ( <View><Loader size="large" /></View> ) ;
    }
        

    function idsFromSwitchIndex(wateringCan, i) {
        let plants = (wateringCan.config || {}).plant || []
        let names = [];

        for (let plant of plants) {
            if (plant.switches && plant.switches[i]) {
                names.push(plant.name);
            }
        }

        return names;
    }

}
