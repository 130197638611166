import React, { useEffect, useState } from 'react';
import { SelectField, Button, View, Text, Collection, Card, TextField, Heading, Table, TableHead, TableBody, TableRow, TableCell, CheckboxField, Loader } from '@aws-amplify/ui-react';

import { Amplify, API } from 'aws-amplify';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaCircleXmark } from 'react-icons/fa6';
import deviceUtil from './util';

export default function RelayPlans () {
    let [device, setDevice] = useState(null);
    let [version, setVersion] = useState(0);
    let { id } = useParams();

    useEffect(function () {
        let mounted = true;

        API.get("pspDevice", "/Device/get/" + id)
            .then(function (device) {
                if (mounted) {
                    if (!device.relayPlans) {
                        device.relayPlans = [];
                    }

                    setDevice(device);
                }
            })

        return () => { mounted = false; }
    }, [id]);

    function updateRelayPlans() {
        API.post(
                "pspDevice",
                "/Device/update/" + id + "/relayPlans",
                {
                    body: {
                        relayPlans : (device.relayPlans || [])
                    }
                }
            )
            .then(function (result) {
                console.log(result);
            })
            .catch(function (e) {
                console.warn(e, e.stack);
            })
    }

    function deleteRelayPlan(index) {
        let relayPlan = (device.relayPlans[index]);

        if (!relayPlan) {
            return
        }

        if (window.confirm("Delete Relay Plan: " + relayPlan.name)) {
            device.relayPlans.splice(index, 1);
            setVersion(version + 1);
        }
    }


    if (device) {
        let relayPlanCount = (device.wateringCan.config || {}).planCount || 16;
        let switchCount = (device.wateringCan.config || {}).switchCount || 16;
        let sensorCount = (device.wateringCan.config || {}).sensorCount || 16;
        let relayCount = (device.relayCount || 8);
        let sensorLabels = deviceUtil.sensorLabels(sensorCount);

        let relayLabels = [];

        for (let i = 0;i < relayCount;i++) {
            relayLabels.push("R" + (i + 1));
        }

        function addRelayPlan() {
            if (device.relayPlans.length < relayPlanCount) {
                device.relayPlans.push({
                    name : "",
                    weeks : 1,
                    relayTime : 0,
                    relays : Array(relayCount).fill(false),
                    days : Array(7).fill(false),
                    hours : Array(24).fill(false),
                    sensors : Array(switchCount).fill(false),
                    threshold : 0
                });
            }

            setVersion(version + 1);
        }

        function updateCheckboxArray(array, index, e) {
            if (index >= array.length) {
                return;
            }

            array[index] = e.target.checked;
            setVersion(version + 1);
        }

        function updateName(index, e) {
            device.relayPlans[index].name = e.target.value;
            setVersion(version + 1);
        }

        function updateThreshold(index, e) {
            device.relayPlans[index].threshold = parseInt(e.target.value, 10);
            setVersion(version + 1);
        }

        function updateRelayTime(index, e) {
            if (e.target.value) {
                device.relayPlans[index].relayTime = parseInt(e.target.value, 10);
            } else {
                device.relayPlans[index].relayTime = 0;
            }
            setVersion(version + 1);
        }

        function updateRelayField(index, name, e) {
            device.relayPlans[index][name] = e.target.value;
            setVersion(version + 1);
        }

        return (
            <View>
                <Heading level="1">{device.wateringCan.env.name}</Heading> 
                <ol className = "breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to={"/Device/" + id}>{device.deviceId}</Link></li>
                    <li>Relay Plans</li>
                </ol>
                <Collection type = "list" direction = "row" gap = "10px" wrap = "wrap" items={device.relayPlans}>
                    {(relayPlan, index) => (
                        <Card key={index} borderRadius="medium" variation="outlined">
                            <TextField label="Name" value={relayPlan.name} onChange={updateName.bind(null, index)} />
                            <Heading level="5">Days of Week</Heading>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell as="th">Sunday</TableCell>
                                        <TableCell as="th">Monday</TableCell>
                                        <TableCell as="th">Tuesday</TableCell>
                                        <TableCell as="th">Wednesday</TableCell>
                                        <TableCell as="th">Thursday</TableCell>
                                        <TableCell as="th">Friday</TableCell>
                                        <TableCell as="th">Saturday</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        { relayPlan.days.map((dayChecked, index) => <TableCell key={index}>
                                            <CheckboxField key={index} checked={dayChecked} onChange={updateCheckboxArray.bind(null, relayPlan.days, index)} />
                                        </TableCell>)}
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Heading level="5">Hours</Heading>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        { relayPlan.hours.slice(0,12).map((hourChecked, index) => <TableCell as="th" key={index}>{(index < 10 ? "0" + index : index) + ":00"}</TableCell>) }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        { relayPlan.hours.slice(0,12).map((hourChecked, index) => <TableCell key={index} as="th">
                                            <CheckboxField key = {index} checked={hourChecked} onChange={updateCheckboxArray.bind(null, relayPlan.hours, index)} />
                                        </TableCell>) }
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        { relayPlan.hours.slice(12).map((hourChecked, index) => <TableCell as="th" key={index}>{(12 + index) + ":00"}</TableCell>) }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        { relayPlan.hours.slice(12).map((hourChecked, index) => <TableCell as="th" key={index}>
                                            <CheckboxField key={index} checked={hourChecked} onChange={updateCheckboxArray.bind(null, relayPlan.hours, 12 + index)} />
                                        </TableCell>) }
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Heading level="5">Dryness Sensors</Heading>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        { sensorLabels.map((label, index) => <TableCell key={index} as="th">{label}</TableCell>) }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        { sensorLabels.map((label, sensorIndex) => <TableCell key={sensorIndex}><CheckboxField checked={relayPlan.sensors[sensorIndex]} onChange={updateCheckboxArray.bind(null, relayPlan.sensors, sensorIndex)} /></TableCell>) }
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <TextField label="Threshold" value={relayPlan.threshold} onChange={updateThreshold.bind(null, index)} />
                            <SelectField label = "Opperator" value={relayPlan.opperator} onChange={updateRelayField.bind(null, index, 'opperator')}>
                                { ['=', '>', '<', '>=', '<=' ].map(op => <option key={op} value={op}>{op}</option>) }
                            </SelectField>
                            <Heading level="5">Relays</Heading>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        { relayLabels.map((label, index) => <TableCell key={index} as="th">{label}</TableCell>) }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        { relayLabels.map((label, relayIndex) => <TableCell key={relayIndex}><CheckboxField checked={relayPlan.relays[relayIndex]} onChange={updateCheckboxArray.bind(null, relayPlan.relays, relayIndex)} /></TableCell>) }
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <TextField label="On Time" value={relayPlan.relayTime} onChange={updateRelayTime.bind(null, index)} />
                            <Button onClick={deleteRelayPlan.bind(null, index)}><FaCircleXmark /></Button>
                        </Card>
                    )}
                </Collection>
                { device.relayPlans.length < relayPlanCount ? <Button onClick={addRelayPlan}>Add Plan</Button> : null }
                <Button onClick={updateRelayPlans}>Save</Button>
            </View>
        );
    } else {
        return ( <View><Loader size="large" /></View> );
    }
}
