import React, { useEffect, useState } from 'react';
import { View, Heading, Collection, Card, Text, Divider, Button } from '@aws-amplify/ui-react';

import { Amplify, API, Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';

import { Link } from "react-router-dom";

let QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

export default function Index() {
    let [devices, setDevices] = useState([]);
    let [primaryDashboard, setPrimaryDashboard] = useState(null);

    useEffect(function () {
        let mounted = true;

        if (!primaryDashboard) {
            API.get("pspUser", "/User/current")
                .then(function (result) { 
                    return Auth.currentSession();
                })
                .then(function (result) {
                    return API.get(
                        "pspUser",
                        "/User/dashboard/homepage",
                        {
                            queryStringParameters: {
                                sessionName : result.idToken.payload.sub
                            }
                        }
                    );
                })
                .then(function (result) {
                    setPrimaryDashboard(result.url);
                })
        }

        API.get("pspDevice","/Device/list")
            .then(function (result) {
                if (!mounted) {
                    return;
                }

                setDevices(result.devices);
            });

        return function () { mounted = false };
    }, []);

    useEffect(function () {
        if (primaryDashboard) {
            let containerDiv = document.getElementById("primaryDashboard");
            if (!containerDiv.innerHTML) {
                QuickSightEmbedding.createEmbeddingContext().then(function (embeddingContext) {
                    let dashboard = embeddingContext.embedDashboard({
                        url : primaryDashboard,
                        container : containerDiv,
                        scrolling: "no",
                        height: "640px",
                        footerPaddingEnabled: true
                    }, {});
                });
            }
        }
    }, [primaryDashboard]);

    return (
        <View>
            <Heading level="1">Welcome to Wifi Watering Can</Heading>
            <View id = "primaryDashboard" />
            <Collection type = "list" direction="row" gap="10px" wrap="wrap" items={devices}>
                {(device, index) => (
                    <Card key={index} borderRadius="medium" maxWidth="20rem" variation="outlined">
                        <Heading padding="medium">{device.env.name}</Heading>
                        <Text>{device.deviceId}</Text>
                        <Divider padding="xs" />
                        <Link to={"/Device/" + device.deviceId}><Button variation="primary">View</Button></Link>
                    </Card>
                )}
            </Collection>
        </View>
    );
}
