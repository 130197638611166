export default {
    plantsForSwitch : function (device, index) {
        return ((device.wateringCan.config || {}).plants || []).filter(function (plant) {
            return plant.switches[index];
        })
    },
    plantsForSensor : function (device, index) {    
        return ((device.wateringCan.config || {}).plants || []).filter(function (plant) {
            return plant.drynessSensors[index];
        })
    },
    sensorLabels : function (sensorCount) {
        let sensorLabels = Array(sensorCount);

        for (let i = 0;i < sensorCount;i++) {
            let labelIndex = Math.floor(i / 2);
            sensorLabels[i] = (labelIndex + 1) + " " + ((i % 2) ? "B" : "A");
        }

        return sensorLabels;
    }
}
